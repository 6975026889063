<template>
  <base-video :Url="videoUrl"></base-video>
</template>
<script>

import {mapState} from 'vuex'
import BaseVideo from "./baseVideo.vue";
export default {
  components: {
    BaseVideo,
  },
  data() {
    return {
      activeKey: 'tab0',
      videoUrl: '设施设备保养子系统.mp4',
      isPlayingUrl: ''
    }
  },
  beforeDestroy() {
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  watch: {},
  mounted() {

  },
  created() {

  },
  methods: {}
}
</script>
<style lang="scss" scoped>

</style>